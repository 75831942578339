var render = function () {
  var _this = this,
    _vm$data$agency;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('b-card', [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [!!_vm.data.id ? _c('b-row', [_c('b-col', {
    attrs: {
      "md": "8"
    }
  }, [_c('validation-observer', {
    ref: "refFormObserver"
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t()) + " ")]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('resellTicket.form.type'),
      "vid": "type",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('resellTicket.form.type')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("*")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          attrs: {
            "id": "type",
            "placeholder": _vm.$t('resellTicket.form.ph.type'),
            "options": _vm.resellTypes,
            "disabled": "",
            "clearable": false,
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('span', [_vm._v(_vm._s(_vm.$t('resellTicket.type.' + data.label)))])];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('resellTicket.type.' + data.label)))])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.data.type,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "type", $$v);
            },
            expression: "data.type"
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
      }
    }], null, false, 391473870)
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('resellTicket.form.source')
    }
  }, [_c('v-select', {
    attrs: {
      "placeholder": _vm.$t('resellTicket.form.source'),
      "options": _vm.sources,
      "clearable": false,
      "disabled": "",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }], null, false, 525575336),
    model: {
      value: _vm.data.source,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "source", $$v);
      },
      expression: "data.source"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('saleReport.columns.airline')
    }
  }, [_c('v-select', {
    attrs: {
      "placeholder": _vm.$t('saleReport.columns.airline'),
      "options": _vm.airlineSupports,
      "reduce": function reduce(airline) {
        return airline.code;
      },
      "label": "code",
      "clearable": false,
      "disabled": "",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.name) + " (" + _vm._s(data.code) + ") ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.name) + " (" + _vm._s(data.code) + ") ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }], null, false, 1744614472),
    model: {
      value: _vm.data.airlineCode,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "airlineCode", $$v);
      },
      expression: "data.airlineCode"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-form-group', {
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('saleReport.columns.paxName')) + " ")];
      },
      proxy: true
    }], null, false, 4251791001)
  }, _vm._l(_vm.data.paxList, function (item, index) {
    return _c('b-row', {
      key: index,
      staticClass: "my-1"
    }, [_c('b-col', {
      attrs: {
        "md": "3"
      }
    }, [_c('v-select', {
      staticClass: "bg-white",
      attrs: {
        "placeholder": _vm.$t('saleReport.columns.paxType'),
        "options": _vm.paxTypes,
        "clearable": false,
        "disabled": "",
        "rules": "required"
      },
      scopedSlots: _vm._u([{
        key: "option",
        fn: function fn(data) {
          return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.' + data.label)) + " ")])];
        }
      }, {
        key: "selected-option",
        fn: function fn(data) {
          return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.' + data.label)) + " ")])];
        }
      }, {
        key: "no-options",
        fn: function fn() {
          return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
        },
        proxy: true
      }], null, true),
      model: {
        value: item.paxType,
        callback: function callback($$v) {
          _vm.$set(item, "paxType", $$v);
        },
        expression: "item.paxType"
      }
    })], 1), _c('b-col', {
      staticClass: "d-flex gap-1",
      attrs: {
        "md": "9"
      }
    }, [_c('b-form-input', {
      staticClass: "bg-white w-30",
      attrs: {
        "placeholder": _vm.$t('customer.lastName'),
        "disabled": ""
      },
      on: {
        "input": function input($event) {
          item.lastName = _vm.formatterInput($event);
        }
      },
      model: {
        value: item.lastName,
        callback: function callback($$v) {
          _vm.$set(item, "lastName", $$v);
        },
        expression: "item.lastName"
      }
    }), _c('b-form-input', {
      staticClass: "bg-white",
      attrs: {
        "disabled": "",
        "placeholder": _vm.$t('customer.firstName')
      },
      on: {
        "input": function input($event) {
          item.firstName = _vm.formatterInput($event);
        }
      },
      model: {
        value: item.firstName,
        callback: function callback($$v) {
          _vm.$set(item, "firstName", $$v);
        },
        expression: "item.firstName"
      }
    })], 1)], 1);
  }), 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('saleReport.columns.priceTicket')
    }
  }, [_c('IAmInputMoney', {
    attrs: {
      "custom-class": "bg-white",
      "value-money": _vm.data.priceTicket,
      "disabled": "",
      "rules": "required"
    },
    on: {
      "update:valueMoney": function updateValueMoney($event) {
        return _vm.$set(_vm.data, "priceTicket", $event);
      },
      "update:value-money": function updateValueMoney($event) {
        return _vm.$set(_vm.data, "priceTicket", $event);
      }
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('resellTicket.form.price')
    }
  }, [_c('IAmInputMoney', {
    attrs: {
      "value-money": _vm.data.price || 0,
      "custom-class": "bg-white",
      "disabled": "",
      "placeholder": _vm.$t('resellTicket.form.ph.price')
    },
    on: {
      "update:valueMoney": function updateValueMoney($event) {
        return _vm.$set(_vm.data, "price || 0", $event);
      },
      "update:value-money": function updateValueMoney($event) {
        return _vm.$set(_vm.data, "price || 0", $event);
      }
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('resellTicket.form.phone'),
      "vid": "phone",
      "disabled": "",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('resellTicket.form.phone')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("*")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          directives: [{
            name: "remove-non-numeric-chars",
            rawName: "v-remove-non-numeric-chars.allNumber",
            modifiers: {
              "allNumber": true
            }
          }],
          attrs: {
            "name": _vm.$t('resellTicket.form.phone'),
            "disabled": "",
            "placeholder": _vm.$t('resellTicket.form.ph.phone')
          },
          model: {
            value: _vm.data.phone,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "phone", $$v);
            },
            expression: "data.phone"
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
      }
    }], null, false, 1109011967)
  })], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('resellTicket.form.note'),
      "label-for": "note",
      "disabled": "",
      "placeholder": _vm.$t('resellTicket.form.ph.note')
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "note",
      "disabled": ""
    },
    model: {
      value: _vm.data.note,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "note", $$v);
      },
      expression: "data.note"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": _vm.$t('resellTicket.form.expiredDate'),
      "disabled": "",
      "vid": "resellTicket-expiredDate"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('resellTicket.form.expiredDate')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("*")])];
            },
            proxy: true
          }], null, true)
        }, [_c('flat-pickr', {
          staticClass: "d-none",
          model: {
            value: _vm.data.expiredDate,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "expiredDate", $$v);
            },
            expression: "data.expiredDate"
          }
        }), _c('div', {
          staticClass: "p-0 form-control",
          class: validationContext.errors[0] ? 'is-invalid' : ''
        }, [_c('flat-pickr', {
          attrs: {
            "id": "resellTicket-expiredDate",
            "name": _vm.$t('resellTicket.form.expiredDate'),
            "disabled": "",
            "config": Object.assign({}, _vm.configFlatPickr, {
              altInputClass: 'form-control border-transparent px-50 py-0 height-fit'
            }),
            "placeholder": _vm.$t('topup.placeholderSelectDate')
          },
          model: {
            value: _vm.data.expiredDate,
            callback: function callback($$v) {
              _vm.$set(_vm.data, "expiredDate", $$v);
            },
            expression: "data.expiredDate"
          }
        })], 1), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
      }
    }], null, false, 3939722493)
  })], 1), _c('b-col', [_c('b-form-checkbox', {
    attrs: {
      "name": "check-button",
      "disabled": "",
      "switch": ""
    },
    model: {
      value: _vm.data.isActive,
      callback: function callback($$v) {
        _vm.$set(_vm.data, "isActive", $$v);
      },
      expression: "data.isActive"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('resellTicket.btn.active')) + " ")])], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    staticClass: "border-left",
    attrs: {
      "md": "4"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('span', [_vm._v(_vm._s(_vm.$t('resellTicket.btn.reportDetail')))]), _vm.hasRoleBan && _vm.isRoleF1 && new Date(_vm.data.agency.expiredDateBanMkp) < new Date() ? _c('b-button', {
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": function click() {
        _this.$bvModal.show('ban-ag-modal');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('resellTicket.btn.ban')) + " ")]) : _vm._e(), new Date(_vm.data.agency.expiredDateBanMkp) > new Date() ? _c('span', {
    staticClass: "text-danger"
  }, [new Date(_vm.data.agency.expiredDateBanMkp) - new Date() > 100 * 365.25 * 24 * 60 * 60 * 1000 ? _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" Cấm vĩnh viễn ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('resellTicket.banTo')) + ": " + _vm._s(_vm.convertISODateTime(_vm.data.agency.expiredDateBanMkp).dateTime) + " ")]), _vm.hasRoleBan && _vm.isRoleF1 ? _c('b-button', {
    staticClass: "ml-1",
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": _vm.confirmUnbanAG
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('resellTicket.btn.unban')) + " ")]) : _vm._e()], 1) : _vm._e()], 1), _vm.reports && _vm.reports.length > 0 ? _c('div', {
    staticClass: "rounded px-2 pb-1 mt-1 bg-info text-white"
  }, [_vm._l(_vm.reports, function (item) {
    return _c('b-row', {
      key: item.id,
      staticClass: "px-1 py-1 d-flex flex-column border-bottom"
    }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('resellTicket.form.phone')) + ": " + _vm._s(item.phone) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.$t('resellTicket.form.reason')) + ": " + _vm._s(item.reason) + " ")])]);
  }), _c('div', {
    staticClass: "d-flex justify-content-center mt-1 align-items-center"
  }, [_vm.isChild == true && _vm.data.isActive ? _c('b-button', {
    attrs: {
      "variant": "secondary"
    },
    on: {
      "click": function click($event) {
        return _vm.confirmDeactive(_vm.data.id);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('resellTicket.btn.deactive')) + " ")]) : _vm._e()], 1)], 2) : _vm._e()])], 1) : _c('b-row', [_vm._v(" " + _vm._s(_vm.$t('resellTicket.notFoundId')) + " ")])], 1)], 1), _c('BanAGModal', {
    attrs: {
      "ag-id": (_vm$data$agency = _vm.data.agency) === null || _vm$data$agency === void 0 ? void 0 : _vm$data$agency.id
    },
    on: {
      "refresh": _vm.fetchDetail
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }