<template>
  <div class="d-flex flex-column">
    <b-card>
      <IAmOverlay :loading="loading">
        <b-row v-if="!!data.id">
          <b-col md="8">
            <validation-observer ref="refFormObserver">
              <b-form>
                <b-row>
                  <b-col md="12">
                    {{ $t() }}
                  </b-col>
                  <b-col md="6">
                    <validation-provider
                      #default="validationContext"
                      :name="$t('resellTicket.form.type')"
                      vid="type"
                      rules="required"
                    >
                      <b-form-group>
                        <template #label>
                          {{ $t('resellTicket.form.type') }}
                          <span class="text-danger">*</span>
                        </template>
                        <v-select
                          id="type"
                          v-model="data.type"
                          :placeholder="$t('resellTicket.form.ph.type')"
                          :options="resellTypes"
                          disabled
                          :clearable="false"
                          rules="required"
                        >
                          <template #option="data">
                            <span>{{ $t('resellTicket.type.' + data.label) }}</span>
                          </template>
                          <template #selected-option="data">
                            <span> {{ $t('resellTicket.type.' + data.label) }}</span>
                          </template>
                          <template #no-options>
                            {{ $t('noOptions') }}
                          </template>
                        </v-select>
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col md="6">
                    <b-form-group :label="$t('resellTicket.form.source')"><v-select
                      v-model="data.source"
                      :placeholder="$t('resellTicket.form.source')"
                      :options="sources"
                      :clearable="false"
                      disabled
                      rules="required"
                    >
                      <template #option="data">
                        <span>
                          {{ data.label }}
                        </span>
                      </template>

                      <template #selected-option="data">
                        <span>
                          {{ data.label }}
                        </span>
                      </template>

                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group :label="$t('saleReport.columns.airline')">
                      <v-select
                        v-model="data.airlineCode"
                        :placeholder="$t('saleReport.columns.airline')"
                        :options="airlineSupports"
                        :reduce="airline => airline.code"
                        label="code"
                        :clearable="false"
                        disabled
                        rules="required"
                      >
                        <template #option="data">
                          <span>
                            {{ data.name }} ({{ data.code }})
                          </span>
                        </template>

                        <template #selected-option="data">
                          <span>
                            {{ data.name }} ({{ data.code }})
                          </span>
                        </template>

                        <template #no-options>
                          {{ $t('noOptions') }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <template #label>
                        {{ $t('saleReport.columns.paxName') }}
                      </template>

                      <b-row
                        v-for="(item, index) in data.paxList"
                        :key="index"
                        class="my-1"
                      >
                        <b-col md="3">
                          <v-select
                            v-model="item.paxType"
                            class="bg-white"
                            :placeholder="$t('saleReport.columns.paxType')"
                            :options="paxTypes"
                            :clearable="false"
                            disabled
                            rules="required"
                          >
                            <template #option="data">
                              <span>
                                {{ $t('reservation.' + data.label) }}
                              </span>
                            </template>

                            <template #selected-option="data">
                              <span>
                                {{ $t('reservation.' + data.label) }}
                              </span>
                            </template>

                            <template #no-options>
                              {{ $t('noOptions') }}
                            </template>
                          </v-select>
                        </b-col>
                        <b-col
                          md="9"
                          class="d-flex gap-1"
                        >
                          <b-form-input
                            v-model="item.lastName"
                            class="bg-white w-30"
                            :placeholder="$t('customer.lastName')"
                            disabled
                            @input="$event => {
                              item.lastName = formatterInput($event);
                            }
                            "
                          />
                          <b-form-input
                            v-model="item.firstName"
                            class="bg-white"
                            disabled
                            :placeholder="$t('customer.firstName')"
                            @input="$event => {
                              item.firstName = formatterInput($event);
                            }
                            "
                          />
                        </b-col>
                      </b-row>

                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group :label="$t('saleReport.columns.priceTicket')">
                      <IAmInputMoney
                        custom-class="bg-white"
                        :value-money.sync="data.priceTicket"
                        disabled
                        rules="required"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="6">
                    <b-form-group :label="$t('resellTicket.form.price')">
                      <IAmInputMoney
                        :value-money.sync="data.price || 0"
                        custom-class="bg-white"
                        disabled
                        :placeholder="$t('resellTicket.form.ph.price')"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <validation-provider
                      #default="validationContext"
                      :name="$t('resellTicket.form.phone')"
                      vid="phone"
                      disabled
                      rules="required"
                    >
                      <b-form-group>
                        <template #label>
                          {{ $t('resellTicket.form.phone') }}
                          <span class="text-danger">*</span>
                        </template>
                        <b-form-input
                          v-model="data.phone"
                          v-remove-non-numeric-chars.allNumber
                          :name="$t('resellTicket.form.phone')"
                          disabled
                          :placeholder="$t('resellTicket.form.ph.phone')"
                        />
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col>

                    <!-- Textarea -->
                    <b-form-group
                      :label="$t('resellTicket.form.note')"
                      label-for="note"
                      disabled
                      :placeholder="$t('resellTicket.form.ph.note')"
                    >
                      <b-form-textarea
                        id="note"
                        v-model="data.note"
                        disabled
                      />
                    </b-form-group>

                  </b-col>
                  <b-col sm="12">
                    <validation-provider
                      #default="validationContext"
                      rules="required"
                      :name="$t('resellTicket.form.expiredDate')"
                      disabled
                      vid="resellTicket-expiredDate"
                    >
                      <b-form-group>
                        <template #label>
                          {{ $t('resellTicket.form.expiredDate') }}
                          <span class="text-danger">*</span>
                        </template>
                        <flat-pickr
                          v-model="data.expiredDate"
                          class="d-none"
                        />
                        <div
                          class="p-0 form-control"
                          :class="validationContext.errors[0] ? 'is-invalid' : ''"
                        >
                          <flat-pickr
                            id="resellTicket-expiredDate"
                            v-model="data.expiredDate"
                            :name="$t('resellTicket.form.expiredDate')"
                            disabled
                            :config="{
                              ...configFlatPickr,
                              altInputClass: 'form-control border-transparent px-50 py-0 height-fit',
                            }"
                            :placeholder="$t('topup.placeholderSelectDate')"
                          />
                        </div>
                        <b-form-invalid-feedback>
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>

                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col>
                    <b-form-checkbox
                      v-model="data.isActive"
                      name="check-button"
                      disabled
                      switch
                    >
                      {{ $t('resellTicket.btn.active') }}
                    </b-form-checkbox>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-col>
          <b-col
            md="4"
            class="border-left"
          >
            <div class="d-flex justify-content-between align-items-center">
              <span>{{ $t('resellTicket.btn.reportDetail') }}</span>
              <b-button
                v-if="hasRoleBan && isRoleF1 && new Date(data.agency.expiredDateBanMkp) < new Date()"
                variant="danger"
                @click="() => {
                  this.$bvModal.show('ban-ag-modal')
                }"
              >
                {{ $t('resellTicket.btn.ban') }}
              </b-button>
              <span
                v-if="new Date(data.agency.expiredDateBanMkp) > new Date()"
                class="text-danger"
              >

                <span
                  v-if="new Date(data.agency.expiredDateBanMkp) - new Date() > 100 * 365.25 * 24 * 60 * 60 * 1000"
                  class="text-danger"
                >
                  Cấm vĩnh viễn
                </span>
                <span v-else>
                  {{ $t('resellTicket.banTo') }}:
                  {{ convertISODateTime(data.agency.expiredDateBanMkp).dateTime }}
                </span>

                <b-button
                  v-if="hasRoleBan && isRoleF1"
                  variant="danger"
                  class="ml-1"
                  @click="confirmUnbanAG"
                >
                  {{ $t('resellTicket.btn.unban') }}
                </b-button>
              </span>

            </div>
            <div
              v-if="reports && reports.length > 0"
              class="rounded px-2 pb-1 mt-1 bg-info text-white"
            >
              <b-row
                v-for="item in reports"
                :key="item.id"
                class="px-1 py-1 d-flex flex-column border-bottom"
              >
                <span>
                  {{ $t('resellTicket.form.phone') }}: {{ item.phone }}
                </span>
                <span>
                  {{ $t('resellTicket.form.reason') }}: {{ item.reason }}
                </span>
              </b-row>

              <div class="d-flex justify-content-center mt-1 align-items-center">
                <b-button
                  v-if="isChild == true && data.isActive"
                  variant="secondary"
                  @click="confirmDeactive(data.id)"
                >
                  {{ $t('resellTicket.btn.deactive') }}
                </b-button>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row v-else>
          {{ $t('resellTicket.notFoundId') }}
        </b-row>
      </IAmOverlay>
    </b-card>
    <BanAGModal
      :ag-id="data.agency?.id"
      @refresh="fetchDetail"
    />
  </div>

</template>

<script>
import {
  computed,
  onMounted,
  ref,
} from '@vue/composition-api'
import {
  BButton,
  BCard,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BRow,
} from 'bootstrap-vue'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import { sizePerPageLgOptions } from '@/constants/selectOptions'
import VueI18n from '@/libs/i18n'
import router from '@/router'
import store from '@/store'

import {
  convertISODateTime,
  formatVnCurrency,
  isDateGreaterThanOrEqualToToday,
} from '@core/utils/filter'

import useResellTicketHandle from '@saleReport/useResellTicketHandle'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    flatPickr: () => import('vue-flatpickr-component'),
    vSelect: () => import('vue-select'),
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    BanAGModal: () => import('@saleReport/resell-ticket/BanAGModal.vue'),
    IAmInputMoney: () => import('@/components/IAmInputMoney.vue'),
  },
  setup() {
    const {
      loading,
      getSellById,
      airlineSupports,
      paxTypes,
      sources,
      resellTypes,
      getReportsById,
      deactiveResellTicket,
      unbanAGByAGId,
    } = useResellTicketHandle()

    const data = ref({})
    const reports = ref([])
    const isChild = ref(false)

    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const meData = computed(() => store.getters['userStore/getMeData'])
    const meDataType = computed(() => store.getters['userStore/getMeDataType'])
    const hasRoleBan = computed(() => ['ADM', 'OPE', 'SE'].includes(meDataType.value) && isRoleF1.value)

    function confirmDeactive(id) {
      this.$refs.refFormObserver.validate().then(async success => {
        if (success) {
          await deactiveResellTicket(id)
          data.value = await getSellById(router.currentRoute.params.id)
        }
      })
    }

    function confirmUnbanAG() {
      const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('modal.confirmUnban') } })
      this.$bvModal
        .msgBoxConfirm(titleMsg(), {
          title: this.$t('modal.confirmUnban'),
          size: 'sm',
          okVariant: 'info',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-danger',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            unbanAGByAGId(data.value.agency.id).then(() => {
              getSellById(router.currentRoute.params.id).then(result => {
                data.value = result
              })
            })
          }
        })
    }

    const locale = computed(() => VueI18n.locale).value
    const configFlatPickr = ref(
      {
        dateFormat: 'Y-m-d',
        minDate: 'today',
        maxDate: '',
        locale: locale === 'vi' ? Vietnamese : null,
        allowInput: true,
        altInput: true,
        altFormat: 'd-m-Y',
        shorthandCurrentMonth: true,
        disableMobile: true,
      },
    )
    async function fetchDetail() {
      data.value = await getSellById(router.currentRoute.params.id)
      isChild.value = isRoleF1.value || [data.value?.agencySellCode, ...(data.value?.parentAgencySellCode ? [data.value?.parentAgencySellCode] : [])].includes(meData.value?.agency.agencyCode)
    }
    onMounted(async () => {
      fetchDetail()
      reports.value = await getReportsById(router.currentRoute.params.id)
    })
    return {
      fetchDetail,
      airlineSupports,
      configFlatPickr,
      confirmDeactive,
      confirmUnbanAG,
      convertISODateTime,
      data,
      formatVnCurrency,
      getSellById,
      hasRoleBan,
      isChild,
      isDateGreaterThanOrEqualToToday,
      loading,
      paxTypes,
      reports,
      resellTypes,
      sizePerPageLgOptions,
      sources,
      isRoleF1,
    }
  },
}
</script>

<style lang="scss" scoped></style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
